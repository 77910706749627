/**section {
    height: 90vh;
}
**/

.styles_react-code-input-container__tpiKG .custom-register-class {
    width: auto!important;
}

.pass-wrapper {
    position: relative;
    display: block;
    margin-bottom: 14px;
}

i {
    position: absolute;
    top: 0.76rem;
    right: 8%;
}

i:hover {
    color: #1FB487;
    cursor: pointer;
}


.googleButtonWraper button {
    /*
    */
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    height: 40px !important;
    width: 100% !important;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 0px !important;
    border-radius: 9px !important;
    border: 1px solid black!important;
    color: black !important;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    opacity: 0.6!important;
    cursor: pointer;
    margin-bottom: 18px !important;
}

.googleButtonWraper span {
    /*    
    */
    padding: 0px 60px !important;
    color: black !important;
    text-align: center !important;
}

.googleButtonWraper div {
    /*
    */
    margin: 0 10px !important; 
    padding: 0px !important;
}


.form-control {
    border: none !important;
    color: black !important;
  }

::placeholder {
    color: black !important;
}

.title-terms {
    color: #848C9D !important;
}

.CreditCardDetails input {
    font-size: 70px;
    text-align: center;
    border: none;
}

.invalideTextForCorporativeEmail {
    font-size: 12px;
    line-height: 24px;
    color: #dc3545;
    margin-bottom: 10px;
    text-align: left;
}


.text-center {
    text-align: center;
}

.bg-gradient-shamrock-green-light-orange {
    background-image: linear-gradient(
20deg
, rgb(31 180 135 / 18%) 0%, rgb(232 191 71 / 19%) 100%) !important ;
}


.video-react .video-react-big-play-button {
    display: none!important;
}


.video-react .video-react-poster {
    background-color: #ffffff!important;
}


/* react horizontal scrolling menu */

.menu-item {
    padding: 0 40px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: none;
  }
  .menu-item-wrapper.active {
    border: 1px blue solid;
  }
  .menu-item.active {
    border: 1px green solid;
  }
  
  .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
  }

/* END react horizontal scrolling menu */





/*
.bg-height {
    min-height: 650px !important;
}
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #ffffff;
    border-radius: .3rem;
}

.btn-primary {
    color: #fff;
    background-color: #17b300;
    border-color: #00e23d;
}



.display {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    width: 32px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    position: relative;
}
.display:last-child {
    border-right: none;
}

.wrap {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    position: relative;
    display: flex;
}



.styles_react-code-input__CRulA > input {
    
    border: none;
    background-color: #cccccc;
    margin-left: 5px;
}

.custom-register-class {
    width: 408px!important;
}


.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.5;
    color: #495057;
    background-color: #efefef;
    background-clip: padding-box;
    border: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.small-input-feed {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #969696;
    text-align: left;
}

*/

.btn-footer {
    background-color: #e4e5e6;
    color: #1FB487;
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    border-radius: 9px;
    text-transform: none;
    padding: 2px 10px;
}

ul, ol, dl {
padding: 0 25px;
}